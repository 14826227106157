import React from "react";
import styled from "styled-components";
import { fontSizes, colors, breaks } from "./common";
import { Fade } from "react-reveal";
import playVideoImg from "../images/playbutton.svg";
import VideoModal from "./VideoModal";
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  ${(props) =>
    props.colWidth
      ? `
        flex: 0 0 80%;
    `
      : ``};

  @media (max-width: ${breaks.md}) {
    flex-basis: 100%;
    width: 100%;
    justify-content: flex-start;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .text {
      max-width: 100%;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;

  @media (min-width: ${breaks.md}) {
  }

  @media (min-width: ${breaks.lg}) {
    padding: 0 0;
    flex-direction: row;
    flex-wrap: wrap;
  }

  // Hide in IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
`;

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  object-fit: contain;
  opacity: 1;
  transition: 0.15s ease-in-out;
  cursor: pointer;

  @media (min-width: ${breaks.md}) {
    width: 146px;
    height: 146px;
  }
`;

const TextColumn = styled(Column)`
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 0;
  ${(props) => (props.reverse ? `order: -1` : ``)};
  @media (min-width: ${breaks.lg}) {
    ${(props) => (props.reverse ? `order: initial` : ``)};
  }

  @media (min-width: ${breaks.lg}) {
    width: 50%;
  }

  h3 {
    margin: 0;
  }
`;
export const VideoSection = (props) => {
  return (
    <Row
      left={props.left}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
    >
      <Column className={props.className}>
        <VideoModal videoSource={props.videoSource}>
          {props.children}

          <PlayButton src={playVideoImg}></PlayButton>
        </VideoModal>
      </Column>
      <TextColumn className={props.className} reverse={props.reverse}>
        <Fade bottom>
          <div className="text">
            <h3>{props.header}</h3>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </div>
        </Fade>
      </TextColumn>
    </Row>
  );
};

const StyledVideoSection = styled(VideoSection)`
  color: ${colors.text};
  font-size: ${fontSizes.text};
  font-weight: normal;
  line-height: 1.2;
  position: relative;

  @media (max-width: ${breaks.md}) {
    font-size: 18px;
    line-height: 1.3;
    height: auto;
  }

  h3 {
    font-size: 52px;
    line-height: 1.1;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0.5em;

    @media (max-width: ${breaks.md}) {
      font-size: 36px;
    }
  }

  .text {
    padding: 1em 0;
    min-width: 0;

    @media (min-width: ${breaks.md}) {
    }

    @media (min-width: ${breaks.lg}) {
      padding: 0 min(5vw, 140px);
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;

    @media (min-width: ${breaks.md}) {
      width: 100%;
    }

    @media (min-width: ${breaks.xxxl}) {
    }
  }
`;

export default StyledVideoSection;
