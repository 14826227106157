import React, { useState } from "react";
import styled from "styled-components";
import CloseButton from "../images/closebutton.svg";

const ModalWrapper = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.isOpen === true
      ? `background-color:rgba(0, 0, 0, 0.9);`
      : `background-color:rgba(0, 0, 0, 0.0);`}

  transition: background-color 0.2s ease;

  ${(props) => (props.isOpen === true ? `display:flex;` : `display:none;`)}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  video {
    width: 90%;
  }

  img {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    max-width: 1.5em;
    height: auto;
  }
`;

const ModalVideo = (props) => {
  if (props.isOpen)
    return (
      <ModalWrapper isOpen={props.isOpen} onClick={props.onClose}>
        <video controls autoPlay src={props.src} />
        <img src={CloseButton} onClick={props.onClose} />
      </ModalWrapper>
    );
};

const VideoModal = (props) => {
  const [isOpen, setOpen] = useState(false);

  const openModal = () => {
    document.body.style.overflow = "hidden"; // prevent body scrolling
    setOpen(true);
  };
  const closeModal = (e) => {
    //e.stopPropagation();  // prevent open retriggering
    document.body.style.overflow = "auto"; // allow body scrolling
    setOpen(false);
  };

  return (
    <>
      <ModalVideo
        isOpen={isOpen}
        src={props.videoSource}
        onClose={(e) => closeModal(e)}
      />
      <div onClick={() => openModal()}>{props.children}</div>
    </>
  );
};

export default VideoModal;
