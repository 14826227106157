const fontSizes = {
  headers: "58px",
  textSubHeader: "24px",
  text: "22px",
  highlight: "32px",
  footer: "20px",
};

const colors = {
  text: "#000000",
  highlight: "#fecd32",
  white: "#FFFFFF",
};

const breaks = {
  sm: "576px",
  md: "768px",
  lg: "1024px",
  xl: "1200px",
  xxl: "1440px",
  xxxl: "1920px",
};

export { colors, fontSizes, breaks };
