import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { fontSizes, breaks, colors } from "./common";

const SplashScreen = (props) => {
  return (
    <div className={props.className}>
      <SplashImageContainer>
        <StaticImage
          src={"../images/image-stage@2x.png"}
          alt={""}
          width={1920}
          height={1110}
          layout={"constrained"}
          objectFit={"cover"}
          objectPosition={"0% 100%"}
        />
      </SplashImageContainer>
    </div>
  );
};
const SplashImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 90%;

  @media (max-width: ${breaks.md}) {
    width: calc(100% - 0.5em);
    min-height: 250px;
    top: 0.5em;
    right: 0.5em;
    //margin-bottom: -3em;*/
  }
`;

const StyledSplashScreen = styled(SplashScreen)`
  position: relative;
  padding: 0;
  max-height: 85vh;

  @media (max-width: ${breaks.md}) {
    height: auto;
  }

  @media (min-width: ${breaks.xl}) {
    overflow: hidden;
  }

  // Hide in IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
`;

export default StyledSplashScreen;
