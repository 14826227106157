import React, { useEffect, useRef, useState } from "react";
import playVideoImg from "../images/playbutton.svg";
import styled from "styled-components";
import { useSwiperSlide } from "swiper/react";

const PlayButton = styled.img`
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 120px;
  height: 120px;
  object-fit: contain;
  opacity: 1;
  transition: 0.15s ease-in-out;

  ${(props) => (props.isPlaying === true ? `opacity: 0;` : ` opacity: 1;`)}
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

export const VideoInline = ({ src, poster }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef();

  const swiperSlide = useSwiperSlide();
  const inActiveSlide = swiperSlide.isActive;

  useEffect(() => {
    if (!inActiveSlide) {
      videoRef.current.pause();
      setVideoPlaying(false);
    }
  }, [inActiveSlide]);

  const handleVideoPlay = () => {
    if (videoPlaying) {
      videoRef.current.pause();
      setVideoPlaying(false);
    } else {
      videoRef.current.play();
      setVideoPlaying(true);
    }
  };

  return (
    <VideoWrapper onClick={handleVideoPlay}>
      <Video ref={videoRef} poster={poster}>
        <source src={src} type="video/mp4" />
      </Video>
      <PlayButton src={playVideoImg} isPlaying={videoPlaying} />
    </VideoWrapper>
  );
};

export default VideoInline;
