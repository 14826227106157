import * as React from "react";
import styled from "styled-components";
import { fontSizes, colors, breaks } from "./common";
import { Fade } from "react-reveal";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  ${(props) =>
    props.colWidth
      ? `
        flex: 0 0 ${props.colWidth};
    `
      : ``};

  @media (min-width: ${breaks.xxxl}) {
    ${(props) =>
      props.colWidth
        ? `
            flex: 0 0 800px;
        `
        : ``};
  }

  @media (max-width: 767px) {
    flex-basis: 100%;
    width: 100%;
    justify-content: flex-start;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;

  @media (min-width: ${breaks.md}) {
    flex-direction: ${(props) => (props.left ? "row" : "row-reverse")};
    flex-wrap: wrap;
  }

  @media (min-width: ${breaks.lg}) {
    ${(props) => (props.paddingLeft === "none" ? `padding-left: 0;` : null)}
    ${(props) => (props.paddingLeft === "single" ? `padding-left: 5%;` : null)}
    ${(props) => (props.paddingLeft === "double" ? `padding-left: 10%;` : null)}
    ${(props) => (props.paddingRight === "none" ? `padding-right: 0%;` : null)}
    ${(props) =>
      props.paddingRight === "single" ? `padding-right: 5%;` : null}
    ${(props) =>
      props.paddingRight === "double" ? `padding-right: 10%;` : null}
  }

  // Hide in IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
`;

const TextSection = (props) => {
  return (
    <Row
      left={props.left}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
    >
      <Column className={props.className} colWidth={props.colWidthText}>
        <Fade bottom>
          <div className="text">
            <h3>{props.header}</h3>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </div>
        </Fade>
      </Column>
      <Column className={props.className}>{props.children}</Column>
    </Row>
  );
};

const StyledTextSection = styled(TextSection)`
  color: ${colors.text};
  font-size: ${fontSizes.text};
  font-weight: normal;
  line-height: 1.2;
  position: relative;

  @media (max-width: ${breaks.md}) {
    font-size: 18px;
    line-height: 1.3;
    height: auto;
  }

  h3 {
    font-size: 52px;
    line-height: 1.1;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0.5em;

    @media (max-width: ${breaks.md}) {
      font-size: 36px;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;
  }

  // Hightlighted text
  em {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 12%,
      ${colors.highlight} 12%,
      ${colors.highlight} 88%,
      rgba(255, 255, 255, 0) 88%
    );
    font-style: normal;
  }
`;

export const ContentThinkAboutTomorrow = styled(StyledTextSection)`
  justify-content: flex-start;

  h3 {
    margin-top: 0.5em;
  }

  @media (min-width: ${breaks.md}) {
    h3 {
      margin-top: 0;
    }
    .text {
      padding: 0 3.3em 0 0;
    }
  }

  @media (max-width: ${breaks.md}) {
    .text {
      padding-bottom: 2em;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;

    @media (min-width: ${breaks.lg}) {
      max-width: 300px;
    }

    @media (min-width: ${breaks.lg}) {
      max-width: 500px;
    }
  }
`;

export const ContentThinkAboutTomorrowSecondaryImage = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  height: 278px;
  height: auto;
  max-width: 100%;

  @media (min-width: ${breaks.md}) {
    display: block;
    margin-top: 0;
    margin-left: 5%;
    margin-bottom: 10%;
    max-width: 50%;
  }

  @media (min-width: ${breaks.lg}) {
    width: 500px;
  }

  @media (min-width: ${breaks.xl}) {
    margin-top: -10%;
    width: 500px;
  }

  @media (min-width: ${breaks.xxl}) {
    margin-top: -5%;
  }

  @media (min-width: ${breaks.xxxl}) {
    width: 700px;
    margin-top: -5%;
  }
`;

export const ContentCenteredImage = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;

  padding: 10%;

  @media (min-width: ${breaks.md}) {
    padding: 10% 0 10% 20%;
  }

  @media (min-width: ${breaks.xl}) {
    padding: 20% 0 10% 10%;
  }

  .gatsby-image-wrapper {
    max-width: 290px;
    max-height: 328px;

    @media (min-width: ${breaks.xxl}) {
      max-width: 435px;
      max-height: 492px;
    }
  }

  @media (min-width: ${breaks.xxxl}) {
    padding: 20% 0 5% 10%;
  }
`;

export const SingleText = styled(Column)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  font-weight: bold;
  padding: 5% 1em;

  h3 {
    font-size: 52px;
    line-height: 54px;
    font-weight: 900;
    margin: 0 0 0.5em 0;
  }

  div.text {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
  }
`;

export const SingleRow = styled(Row)`
  justify-content: flex-end;
  padding: 5% 0;
  ${(props) => (props.center === true ? `justify-content: center;` : null)}
`;

const SingleTextSection = (props) => {
  return (
    <SingleRow className={props.className} center={props.center}>
      <SingleText center={props.center}>
        <Fade bottom>
          <div className="text">
            <h3>{props.header}</h3>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </div>
        </Fade>
      </SingleText>
    </SingleRow>
  );
};

export const ContentWellDone = styled(SingleTextSection)`
  text-align: center;

  font-weight: 900;

  @media (min-width: ${breaks.md}) {
    padding-left: 1em;
  }

  @media (min-width: ${breaks.lg}) {
  }

  div.text {
    text-align: center;
    font-weight: 900;
    max-width: 852px;
  }
`;

export const ContentStrongTogeather = styled(SingleTextSection)`
  max-width: 710px;

  @media (min-width: ${breaks.md}) {
    padding-left: 10%;
  }

  @media (min-width: ${breaks.lg}) {
    padding-left: min(5vw, 170px);
  }
  @media (min-width: ${breaks.xxxl}) {
    max-width: 900px;
  }
`;

export default StyledTextSection;
