import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { breaks } from "./common";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

const LineSection = styled.div`
  position: relative;
  overflow: visible;

  @media (max-width: 1023px) {
    padding: 0 !important;
  }
`;

const Line = styled.svg`
  display: none;
  @media (min-width: ${breaks.md}) {
    display: block;
  }
`;

const Line1 = styled(Line)`
  position: absolute;
  bottom: -30%;
  right: 0px;
  height: auto;
  width: 100%;
`;

const Line2 = styled(Line)`
  position: absolute;
  top: -6%;
  right: 0px;
  height: auto;
  width: 100%;
`;
const Line3 = styled(Line)`
  position: absolute;
  right: 0px;
  height: auto;
  width: 100%;
  bottom: -1em;

  @media (min-width: ${breaks.lg}) {
    bottom: -20%;
  }
`;
const Line4 = styled(Line)`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;

  @media (min-width: ${breaks.md}) {
    top: 40%;
  }

  @media (min-width: ${breaks.lg}) {
    top: 3em;
  }
`;

const Line5 = styled(Line)`
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;

  @media (min-width: ${breaks.xl}) {
    top: -10%;
  }
`;

const StyledLineSection2 = styled(LineSection)`
  padding-bottom: 4em;
  @media (min-width: ${breaks.xxxl}) {
    padding-bottom: 10em;
    position: relative;
    padding-top: 10em;
  }
`;

const useScrollTrigger = (scrollTriggerConfig) => {
  const lineRef = useRef();
  const sectionRef = useRef();

  useEffect(() => {
    if (lineRef.current && sectionRef.current) {
      gsap.set(lineRef.current, { opacity: 1 });
      gsap.from(lineRef.current, {
        duration: 1,
        ease: "none",
        drawSVG: 0,
        scrollTrigger: {
          trigger: sectionRef.current,
          scrub: true,
          toggleActions: "play none none reverse",
          ...scrollTriggerConfig,
        },
      });
    }
  }, [lineRef, sectionRef, scrollTriggerConfig]);

  return { lineRef, sectionRef };
};

export const LineSection1 = (props) => {
  const { lineRef, sectionRef } = useScrollTrigger({
    start: "top top",
  });

  return (
    <LineSection ref={sectionRef}>
      <Line1 height="632" viewBox="0 0 1440 632" width="1440">
        <path
          ref={lineRef}
          d="m1054.30298 12h272.69317c33.78101 0 46.6543 8.1470947 46.6543 46.6542969v405.3457031c1.26074 31.102865-14.29069 46.654297-46.6543 46.654297-48.54541 0-45.8457 0-87.3457 0s-52.31763 14.789551-52.31763 52.317627 9.74219 52.317627 52.31763 52.317627h200.34955"
          fill="none"
          strokeDasharray="0"
          stroke="#fecd32"
          strokeWidth="14"
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={0}
        />
      </Line1>
      {props.children}
    </LineSection>
  );
};

export const LineSection2 = (props) => {
  const { lineRef, sectionRef } = useScrollTrigger({
    start: "top center-=20%",
    end: "bottom center",
  });

  return (
    <StyledLineSection2 className={props.className}>
      <Line2
        width="1440px"
        height="996px"
        viewBox="0 0 1440 996"
        version="1.1"
        ref={sectionRef}
      >
        <g
          id="line-02"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            ref={lineRef}
            d="M1440,11.789856 C1440,11.789856 1234.09265,11.789856 1187.33282,11.789856 C1140.573,11.789856 1147.43231,24 1147.43231,76 C1147.43231,110.666667 1147.43231,338.451538 1147.43231,759.354614 C1147.43231,846.429972 1147.43231,905.300985 1147.43231,935.967651 C1147.43231,981.967651 1149.0318,981.967651 1101.43231,981.967651 C1069.69932,981.967651 733.683634,979.550008 0,974.714722"
            id="Path-Copy"
            stroke="#FECD32"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </Line2>
      {props.children}
    </StyledLineSection2>
  );
};
export const LineSection3 = (props) => {
  const { lineRef, sectionRef } = useScrollTrigger({
    start: "center center",
    end: "bottom center",
  });

  return (
    <LineSection ref={sectionRef}>
      <Line3
        width="1440px"
        height="196px"
        viewBox="0 0 1440 196"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="line-03"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            ref={lineRef}
            d="M0,178.441772 L63,178.441772 L63,17.5207 L648.870968,17.5207 C648.870968,44.4385874 648.870968,71.2587661 648.870968,97.9812362 C648.870968,138.064941 651.096436,178.441772 706,178.441772 C742.602376,178.441772 987.269043,178.441772 1440,178.441772"
            id="Path-2-Copy"
            stroke="#FFCD32"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </Line3>{" "}
      {props.children}
    </LineSection>
  );
};

export const LineSection4 = (props) => {
  const { lineRef, sectionRef } = useScrollTrigger({
    start: "top center-=5%",
    end: "bottom center",
  });

  return (
    <LineSection style={{ paddingTop: "4em" }} ref={sectionRef}>
      <Line4
        width="1440px"
        height="196px"
        viewBox="0 0 1440 196"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="line-04"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            ref={lineRef}
            d="M1440,13 C1204.37036,13 1061.30687,13 1010.80952,13 C935.063494,13 935.063494,181.842468 1010.80952,181.842468 C1061.30687,181.842468 1204.37036,181.842468 1440,181.842468"
            id="Path-5-Copy"
            stroke="#FFCD32"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </Line4>{" "}
      {props.children}
    </LineSection>
  );
};

export const LineSection5 = (props) => {
  const { lineRef, sectionRef } = useScrollTrigger({
    start: "top bottom",
    end: "center top",
  });

  return (
    <LineSection style={{ paddingTop: "4em" }} ref={sectionRef}>
      <Line5
        width="1440px"
        height="1776px"
        viewBox="0 0 1440 1776"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="line-05-2"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            ref={lineRef}
            d="M1439.52893,12 L1139.73837,12 L1139.73837,85.1282959 C755.759301,85.1282959 545.264535,85.1282959 508.254068,85.1282959 C452.738368,85.1282959 446,124.449908 446,147.382364 C446,170.314819 446,793.887207 446,840.196167 C446,886.505127 451.587517,887.625732 508.254068,887.625732 C546.031769,887.625732 585.859869,887.625732 627.738368,887.625732 C652.820376,887.625732 686.159054,887.625732 727.754403,887.625732 C790.147426,887.625732 792.336595,895.384966 792.336595,952.207925 C792.336595,990.089897 792.336595,1087.95298 792.336595,1245.79718 L792.336595,1286.57764 L792.336595,1749.87158"
            id="Path-6-Copy"
            stroke="#FFCD32"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </Line5>{" "}
      {props.children}
    </LineSection>
  );
};
