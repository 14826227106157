import * as React from "react";
import styled from "styled-components";
import { fontSizes, breaks } from "./common";
import imgLogo from "../images/logo.svg";

const Header = (props) => {
  return (
    <header className={props.className}>
      <HeaderInner>
        <img src={imgLogo} alt="Logo" loading="eager" width={180} />
        <h1>Geschäftsbericht 2021</h1>
      </HeaderInner>
      {props.children}
    </header>
  );
};

const StyledHeader = styled(Header)``;

const HeaderInner = styled.div`
  flex: none;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  padding: 2.5em 3.5em 2.5em 3.5em;
  font-size: 0.95em;

  h1 {
    font-size: ${fontSizes.textSubHeader};
    line-height: 1;
    position: relative;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1em;
    height: 100%;

    h1 {
      margin: 1.25em 0 0 0;
    }
  }
`;

export default StyledHeader;
