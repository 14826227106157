import * as React from "react";
import styled from "styled-components";
import "normalize.css";
import ingra_xb from "../fonts/IngraWd-ExtraBold.woff2";
import ingra_xb2 from "../fonts/IngraWd-ExtraBold.woff";
import Header from "../components/Header";
import SplashScreen from "../components/SplashScreen";
import Footer from "../components/Footer";
import { createGlobalStyle } from "styled-components";

import {
  LineSection1,
  LineSection2,
  LineSection3,
  LineSection4,
  LineSection5,
} from "../components/Lines";
import VideoSection from "../components/VideoSection";
import {
  ContentWellDone,
  ContentStrongTogeather,
  ContentCenteredImage,
  ContentThinkAboutTomorrow,
  ContentThinkAboutTomorrowSecondaryImage,
} from "../components/TextSections";
import ReportContainer, {
  StyledIEUnavailable,
} from "../components/ReportContainer";
import Slider from "../components/Slider";
import { colors, fontSizes } from "../components/common";
import { StaticImage } from "gatsby-plugin-image";
import Video_GF from "../video/Film_GF.mp4";
import VideoHousingFirst from "../video/Film_Housing_First.mp4";
import { Helmet } from "react-helmet";

const GlobalCSS = createGlobalStyle`
  html{
    scroll-behavior: smooth;
  }
  body {
    background-color: #F2F2F2;
  }
`;

const Wrapper = styled.main`
  @import url("https://p.typekit.net/p.css?s=1&k=jgt8bgu&ht=tk&f=26494.26500&a=6698789&app=typekit&e=css");

  @font-face {
    font-family: "ingra";
    src: url("https://use.typekit.net/af/ef0034/00000000000000007735a7be/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/ef0034/00000000000000007735a7be/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("woff"),
      url("https://use.typekit.net/af/ef0034/00000000000000007735a7be/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "ingra";
    src: url("https://use.typekit.net/af/6c7559/00000000000000007735a7c4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/6c7559/00000000000000007735a7c4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/6c7559/00000000000000007735a7c4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "ingra";
    src: url("${ingra_xb}") format("woff2"), url("${ingra_xb2}") format("woff");
    font-display: auto;
    font-style: normal;
    font-weight: 900;
  }

  font-family: "ingra", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: ${fontSizes.text};
  color: ${colors.text};

  display: block;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: auto;

  background-color: #ffffff;

  * {
    box-sizing: border-box;
  }
`;
// markup
const IndexPage = () => {
  return (
    <>
      <GlobalCSS />
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Geschäftsbericht 2021 – Aachener Siedlungs- und Wohnungsgesellschaft
            mbH
          </title>
        </Helmet>
        <Header />

        <LineSection1>
          <SplashScreen />
        </LineSection1>
        <ContentWellDone
          center
          header="Gut gemacht"
          text="Das Jahr 2021 hat uns in vielerlei Hinsicht gefordert. Wir sind oft überrascht worden und gleichzeitig über uns hinausgewachsen. Wir haben nicht abgewartet, sondern schnell gehandelt. Wir haben neue Lösungen und Wege entdeckt und uns weiterentwickelt.  Unser Motto in 2021 war: Machen. Aber nicht irgendwie. Kein wilder Aktionismus, sondern mit einem klaren Plan, einer festen Strategie."
        />
        <LineSection2>
          <ContentThinkAboutTomorrow // ContentThinkAboutTomorrow
            left
            header="An Morgen denken"
            text="Der Klimaschutz im Bestand stellt die Wohnungs-wirtschaft vor große Herausforderungen. Denn
              Wohnen ist ein Grundbedürfnis und muss bezahlbar
              bleiben – gleichzeitig ist Klimaschutz aber auch Menschenschutz. Ein schwieriger Balanceakt. Die Aachener versteht es als ihre Aufgabe und Verantwortung, hier sozialverträgliche Lösungen zu finden. Noch haben wir dafür keine „Zauberformel“ 
              gefunden, aber wir werden jeden Tag ein kleines Stückchen schlauer."
            colWidthText="570px"
            paddingLeft={"single"}
            paddingRight={"single"}
          >
            <StaticImage
              src="../images/image-an-morgen-denken@3x.png"
              alt="Am Morgen Denken"
              loading="lazy"
              layout="constrained"
              objectFit={"contain"}
              style={{
                alignSelf: "flex-end",
              }}
            />
          </ContentThinkAboutTomorrow>

          <ContentThinkAboutTomorrowSecondaryImage>
            <StaticImage
              src="../images/image-an-morgen-denken-2@3x.png"
              alt="Am Morgen Denken 2"
              loading="lazy"
              layout="constrained"
            />
          </ContentThinkAboutTomorrowSecondaryImage>
        </LineSection2>
        <LineSection3 style={{ marginBottom: "20%" }}>
          <a id="housing-first" href="#housing-first" />
          <VideoSection
            reverse
            header="Der Mensch im Mittelpunkt"
            text="Die Aachener hat als Unternehmen mit katholischem Hintergrund ihr Leitbild und die katholische Soziallehre fest in ihrer Strategie verankert. Menschen, Menschlichkeit und das Miteinander stehen im Mittelpunkt. Neben dem
            Kerngeschäft engagiert sich das Unternehmen in sozialen Projekten. Beides verbindet sie mit ihren Fähigkeiten und Möglichkeiten als wohnungswirtschaftliches Unternehmen und schafft so immer wieder Wohn- und Lebensräume für Menschen in Notsituationen.
            Unbürokratisch und schnell."
            videoSource={VideoHousingFirst}
          >
            <StaticImage
              src="../images/image-der-mensch-im-mittelpunkt@2x.png"
              alt="Am Morgen Denken"
              loading="lazy"
              layout="constrained"
              objectFit={"contain"}
              style={{
                alignSelf: "flex-start",
              }}
            />
          </VideoSection>
        </LineSection3>
        <LineSection4>
          <a id="neue-aachener" href="#neue-aachener" />
          <ContentStrongTogeather
            header="Gemeinsam stark"
            text="Zusammenhalt, Teamgeist und gegenseitige Unterstützung werden bei der Aachener großgeschrieben. Das zeigt sich zum einen bei der täglichen Zusammenarbeit, zum anderen auch in Projekten, die das Unternehmen erfolgreich meistert. Aber auch in spontanen Hilfsaktionen, bei Veranstaltungen oder bei der ersten Mitarbeiterbefragung."
          />
        </LineSection4>

        <Slider />

        <LineSection5>
          <a id="tag-mit-gf" href="#tag-mit-gf" />
          <VideoSection
            header="Neue Wege beschreiten"
            text="Kann man anhand eines Tages zeigen, was ein Unternehmen an 365 Tagen bewegt hat? Die Aachener, aber auch die Wohnungswirtschaft als Branche
            befinden sich im Umbruch. Die Themen werden komplexer, die Herausforderungen wachsen. Wir haben an einem Freitag im Mai die Geschäftsführung
            einen Tag lang begleitet und dadurch im Kleinen
            einen unmittelbaren Eindruck davon bekommen, welche vielfältigen Aufgaben zu lösen sind."
            videoSource={Video_GF}
          >
            <StaticImage
              src="../images/image-neue-wege-beschreiten@3x.png"
              alt="Neue Wege Beschreiten"
              loading="lazy"
              layout="constrained"
              objectFit={"contain"}
              style={{
                alignSelf: "flex-start",
              }}
            />
          </VideoSection>

          <ContentCenteredImage>
            <StaticImage
              src="../images/image-neue-wege-beschreiten-2@3x.png"
              alt="Neue Wege Beschreiten 2"
              loading="lazy"
              layout="constrained"
            />
          </ContentCenteredImage>
        </LineSection5>

        <ReportContainer />

        <Footer />
      </Wrapper>
      <StyledIEUnavailable />
    </>
  );
};

export default IndexPage;
