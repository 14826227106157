import * as React from "react";
import styled from "styled-components";
import { breaks, colors } from "./common";
import leftArrowImg from "../images/arrow-left.svg";
import rightArrowImg from "../images/arrow-right.svg";

import imgStephan from "../images/slider/img_slider_zehnpfennig_@2x.jpg";
import imgJohanna from "../images/slider/img_slider_bouchannafa_@2x.jpg";
import imgStefan from "../images/slider/img_slider_effenberg_@2x.jpg";
import videoZehnpfennig from "../video/Zehnpfennig.mp4";
import videoEffenberg from "../video/Effenberg.mp4";
import videoBouchannafa from "../video/Bouchannafa.mp4";
import { StaticImage } from "gatsby-plugin-image";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import VideoInline from "./VideoInline";

const SliderWrapper = styled.section`
  margin: 0 auto 0em auto;

  @media (min-width: ${breaks.md}) {
    margin: 0 auto 4em auto;
  }

  @media (min-width: ${breaks.lg}) {
    padding: 1em 2em 5em;
  }

  .swiper-button-next,
  .swiper-button-prev {
    z-index: 1;
    width: 15px;
    height: 24px;

    @media (min-width: ${breaks.md}) {
      width: 30px;
      height: 48px;
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .swiper-button-next:after {
    background-image: url(${rightArrowImg});
  }

  .swiper-button-prev:after {
    background-image: url(${leftArrowImg});
  }
`;

const MediaWrapper = styled.div`
  width: 288px;
  height: 512px;
`;

const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;

  @media (min-width: ${breaks.lg}) {
    flex-direction: row;
    padding: 0;
  }
`;

const StyledQuote = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 455px;
  align-items: flex-start;
  margin: 1em;

  @media (min-width: ${breaks.md}) {
    margin: 2em;
  }

  blockquote {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 1em;
    margin: 0;

    @media (min-width: ${breaks.md}) {
      font-size: 28px;
    }
  }

  span {
    color: ${colors.highlight};
    font-weight: bold;
    text-align: left;
    font-size: 20px;
    line-height: 1.2;
  }
`;

const Quote = (props) => {
  return (
    <StyledQuote>
      <blockquote>{props.text}</blockquote>
      <span>{props.attribution}</span>
      <span>{props.department}</span>
    </StyledQuote>
  );
};

export default class SimpleSlider extends React.Component {
  render() {
    return (
      <SliderWrapper>
        <Swiper
          modules={[Navigation]}
          slidesPerView={1}
          threshold={40}
          navigation
        >
          <SwiperSlide>
            <StyledSlide>
              <MediaWrapper>
                <VideoInline poster={imgJohanna} src={videoBouchannafa} />
              </MediaWrapper>
              <Quote
                text="„In der Kommunikation bei der Aachener ist das Tolle, dass man einfach nicht lange nach guten Geschichten suchen muss und sich mit allen seinen Ideen und Kompetenzen einbringen kann.“"
                attribution="Johanna Bouchannafa, Stabsstelle"
                department="Unternehmenskommunikation"
              />
            </StyledSlide>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSlide>
              <MediaWrapper>
                <StaticImage
                  src="../images/slider/img_slider_gottschalk-werner_@2x.jpg"
                  alt="Lioba"
                  layout="constrained"
                  objectFit="contain"
                  placeholder="none"
                />
              </MediaWrapper>

              <Quote
                text="„Ich freue mich – wieder – Teil der Aachener sein zu dürfen und das Unternehmen weiter voranzubringen, insbesondere die Digitalisierung.“"
                attribution="Lioba Gottschalk-Werner,"
                department="stellvertretende Bereichsleiterin Rechnungswesen"
              />
            </StyledSlide>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSlide>
              <MediaWrapper>
                <VideoInline poster={imgStephan} src={videoZehnpfennig} />
              </MediaWrapper>

              <Quote
                text="„Ich finde es ausgesprochen positiv, wie vielseitig die Aachener sich für breite Bevölkerungsschichten einsetzt. Daran mitzuwirken ist ein schönes Gefühl und auch ein Stück weit Herzensangelegenheit.“"
                attribution="Stephan Zehnpfennig,"
                department="Bereich Neubau"
              />
            </StyledSlide>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSlide>
              <MediaWrapper>
                <StaticImage
                  src="../images/slider/img_slider_zwerner_@2x.jpg"
                  alt="Tim"
                  layout="constrained"
                  objectFit="contain"
                  placeholder="none"
                />
              </MediaWrapper>
              <Quote
                text="„Mich hat die soziale Ausrichtung des Unternehmens überzeugt. Außerdem fand ich die angenehme Atmosphäre im Vorstellungsgespräch und die Perspektiven beeindruckend.“"
                attribution="Tim Zwerner,"
                department="Abteilung Personal"
              />
            </StyledSlide>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSlide>
              <MediaWrapper>
                <VideoInline poster={imgStefan} src={videoEffenberg} />
              </MediaWrapper>
              <Quote
                text="„Die Aachener hat richtig gute Rahmenbedingungen mit flachen Hierarchien und vor allem ein tolles Arbeitsklima.“"
                attribution="Stefan Effenberg,"
                department="Abteilungsleiter IT"
              />
            </StyledSlide>
          </SwiperSlide>
        </Swiper>
      </SliderWrapper>
    );
  }
}
