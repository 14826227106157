import * as React from "react";
import styled, { css } from "styled-components";
import iconBookImg from "../images/icon-book.svg";
import iconPdfImg from "../images/icon-pdf.svg";
import { colors, fontSizes, breaks } from "./common";
import { StaticImage } from "gatsby-plugin-image";
import { withPrefix } from "gatsby-link";

const IEUnavailable = (props) => {
  return (
    <div className={props.className}>
      <LinkContainer>
        <h1>Es tut uns leid. </h1>
        <h3>
          Diese Website ist nicht mit dem Internet Explorer kompatibel.
          Aktualisieren Sie Ihren Browser oder sehen Sie sich den
          Geschäftsbericht 2020 hier an:
        </h3>
        <ReportLink primary href="/epaper/" target="_new">
          <img src={iconBookImg} alt="" />
          Geschäftsbericht online ansehen
        </ReportLink>
        <ReportLink
          target="_download"
          href={withPrefix("/Aachener-SWG_Geschaeftsbericht-2021.pdf")}
        >
          <img src={iconPdfImg} alt="" />
          Geschäftsbericht als PDF herunterladen
        </ReportLink>
      </LinkContainer>
    </div>
  );
};

const ReportContainer = (props) => {
  return (
    <div className={props.className}>
      <StaticImage
        src="../images/image-muenster-kinderhaus@2x.png"
        alt=""
        layout={"fullWidth"}
        objectPosition={"50% 100%"}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          bottom: 0,
        }}
      />
      <LinkContainer>
        <ReportLink primary href={withPrefix("/epaper/")} target="_new">
          <img src={iconBookImg} alt="" />
          Geschäftsbericht online ansehen
        </ReportLink>
        <ReportLink
          href={withPrefix("/Aachener-SWG_Geschaeftsbericht-2021.pdf")}
          target="_download"
          download="aachener-geschaeftsbericht-2021"
        >
          <img src={iconPdfImg} alt="" />
          Geschäftsbericht als PDF herunterladen
        </ReportLink>
      </LinkContainer>
    </div>
  );
};
export const StyledReportContainer = styled(ReportContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
  padding: 1em;
  position: relative;

  @media (max-width: ${breaks.md}) {
    height: auto;
    min-height: 580px;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 4em;
  }
`;

const LinkContainer = styled.div`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  display: block;
  margin: 0 auto;
  width: 80%;
  max-width: 888px;
  background: white;
  height: auto;
  padding: 4.1em;
  font-size: 24px;
  position: relative;

  @media (max-width: ${breaks.md}) {
    width: 100%;
    padding: 1em;
  }
`;
const ReportLink = styled.a`
  font-size: ${(props) =>
    props.primary ? fontSizes.highlight : fontSizes.textSubHeader};

  ${(props) =>
    props.primary
      ? css`
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          background-color: ${colors.highlight};
          transform: scale(1);
          transition: transform 0.15s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }
        `
      : null}

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1em;

  color: ${colors.text};
  font-weight: 700;
  text-decoration: none;

  img {
    margin-top: -3px; // Text line height is being silly, so we have to move the image
    padding: 0 1em 0 0;
  }

  @media (max-width: ${breaks.md}) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      padding: 0 0 1em 0;
    }

    font-size: ${(props) => (props.primary ? "24px" : "16px")};
  }
`;

export const StyledIEUnavailable = styled(IEUnavailable)`
  display: none;
  font-family: "ingra", sans-serif;
  font-weight: 400;
  font-style: normal;

  padding-top: 5em;

  h1 {
    text-align: center;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

export default StyledReportContainer;
